import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FinishedProps } from '../CombinedTables/CombinedTables'
import {
  DailyBalanceData,
  InputBalance,
  OutputBalance
} from '../CompactTable/CompactTable'
import { BoxValues, Unit } from '../DailyTable/DailyTable'
import { DiscardUnit } from '../DiscardUnit/DIscardUnit'

enum tableEnum {
  input = 'Input',
  output = 'Output'
}

interface TableProps {
  type: tableEnum
  total: string
  inputbalance: InputBalance
  outputbalance: OutputBalance
  istoday: boolean
  is24: boolean
}

const BoxValuesBordered = styled(BoxValues)<FinishedProps>`
  border: ${(props) =>
    props.isfinished ? 'none' : '0.0625rem solid var(--border-color)'};
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-right: 0.25rem;
  background-color: ${(props) =>
    props.isfinished ? 'var(--black-bg-90-color)' : 'none'};
  height: calc(100% - 0.5rem);
`
const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap 0.5rem;
  flex-direction: row;
  margin-bottom: -0.5rem;
`
const ScrollTable = styled.div`
  height: 18rem;
  width: 50%;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--border-color);
  display: flex;
  flex-direction: column;
  -ms-overflow-style: none;
  overflow: hidden;
  justify-content: space-between;
`
const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: interMedium;
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--darkfont-color);
`
const Footer = styled.div`
  width: 100%;
  height: 3.125rem;
  background-color: var(--headerbackground-color);
  border-top: 0.0625rem solid var(--border-color);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`
const Value = styled.div`
  font-family: interMedium;
  color: var(--darkFont-color);
  font-weight: 600;
  font-size: 0.75rem;
  margin: 1rem;
`
const ValueNoMargin = styled(Value)<FinishedProps>`
  margin: 0rem;
  color: ${(props) =>
    props.isfinished ? 'var(--white-color)' : 'var(--darkFont-color)'};
  font-size: 1.25rem;
`
const Row = styled.div`
  padding: 1rem 0.5rem;
  border-bottom: 0.0625rem solid var(--border-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
`
const Header = styled(Row)`
  background-color: var(--headerbackground-color);
  margin-left: 0;
  margin-right: 0;
  position: sticky;
  top: 0rem;
  padding: 1rem 1rem;
`
const RowBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
`
const BoxMain = styled.div`
  text-transform: capitalize;
`
const BoxSub = styled(BoxMain)`
  color: var(--greyFont-color);
  text-transform: lowercase;
`
const TotalType = styled.div`
  font-family: interMedium;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--greyFont-color);
`
const OutputEntries = (data: OutputBalance) => {
  const { t } = useTranslation()
  return (
    <>
      <Row>
        <RowBox>
          <BoxMain>{t('Urine')}</BoxMain>
          <BoxSub>{data.countOfUrine}</BoxSub>
        </RowBox>
        <RowBox>
          <BoxMain>
            {data.sumOfUrine === 0 ? '0' : `- ${data.sumOfUrine}`}
          </BoxMain>
          <BoxSub>g</BoxSub>
        </RowBox>
      </Row>
      <Row>
        <RowBox>
          <BoxMain>{t('Fecal')}</BoxMain>
          <BoxSub>{data.countOfFecal}</BoxSub>
        </RowBox>
        <RowBox>
          <BoxMain>
            {data.sumOfFecal === 0 ? '0' : `- ${data.sumOfFecal}`}
          </BoxMain>
          <BoxSub>g</BoxSub>
        </RowBox>
      </Row>
      <Row>
        <RowBox>
          <BoxMain>{t('Other')}</BoxMain>
          <BoxSub>{data.countOfOther}</BoxSub>
        </RowBox>
        <RowBox>
          <BoxMain>
            {data.sumOfOther === 0 ? '0' : `- ${data.sumOfOther}`}
          </BoxMain>
          <BoxSub>g</BoxSub>
        </RowBox>
      </Row>
    </>
  )
}
const InputEntries = (data: InputBalance) => {
  const { t } = useTranslation()
  return (
    <>
      <Row>
        <RowBox>
          <BoxMain>Oral</BoxMain>
          <BoxSub>{data.countOfOral}</BoxSub>
        </RowBox>
        <RowBox>
          <BoxMain>{data.sumOfOral}</BoxMain>
          <BoxSub>g</BoxSub>
        </RowBox>
      </Row>
      <Row>
        <RowBox>
          <BoxMain>{t('Intravenous')}</BoxMain>
          <BoxSub>{data.countOfIV}</BoxSub>
        </RowBox>
        <RowBox>
          <BoxMain>{data.sumOfIV}</BoxMain>
          <BoxSub>g</BoxSub>
        </RowBox>
      </Row>
      <Row>
        <RowBox>
          <BoxMain>Oral + {t('Intravenous')}</BoxMain>
          <BoxSub>{data.countOfDiscard}</BoxSub>
          <DiscardUnit />
        </RowBox>
        <RowBox>
          <BoxMain>{data.sumOfDiscard.toString().replace(/-/g, '- ')}</BoxMain>
          <BoxSub>g</BoxSub>
        </RowBox>
      </Row>
    </>
  )
}
const TableComponent = ({
  type,
  total,
  inputbalance,
  outputbalance,
  istoday,
  is24
}: TableProps) => {
  const { t } = useTranslation()
  const entries =
    type === tableEnum.input
      ? InputEntries(inputbalance)
      : OutputEntries(outputbalance)
  return (
    <ScrollTable>
      <Table>
        <Header>
          <RowBox>
            <BoxMain>{type === tableEnum.input ? 'input' : 'output'}</BoxMain>
            <BoxSub>{t('accumulated')}</BoxSub>
          </RowBox>
        </Header>
        {entries}
      </Table>
      <Footer>
        <BoxValuesBordered isfinished={is24 || !istoday}>
          <Unit>{type} Total</Unit>
          <ValueNoMargin isfinished={is24 || !istoday}>
            {type === tableEnum.output && parseInt(total) !== 0
              ? `- ${total}`
              : total}
          </ValueNoMargin>
          <TotalType>g</TotalType>
        </BoxValuesBordered>
      </Footer>
    </ScrollTable>
  )
}

export const InputOutputTables = ({ data, is24 }: DailyBalanceData) => {
  return (
    <TableWrapper>
      <TableComponent
        type={tableEnum.input}
        inputbalance={data.inputBalance}
        outputbalance={data.outputBalance}
        total={data.inputTotal}
        istoday={data.currentDay}
        is24={is24}
      ></TableComponent>
      <TableComponent
        type={tableEnum.output}
        inputbalance={data.inputBalance}
        outputbalance={data.outputBalance}
        total={data.outputTotal}
        istoday={data.currentDay}
        is24={is24}
      ></TableComponent>
    </TableWrapper>
  )
}
